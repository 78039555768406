import firebase from 'firebase';

firebase.initializeApp({
    apiKey: "AIzaSyCaIelry-T20AYxu8b9-rjn1titK3OFGj8",
    authDomain: "jobchance-88be8.firebaseapp.com",
    databaseURL: "https://jobchance-88be8.firebaseio.com",
    projectId: "jobchance-88be8",
    storageBucket: "jobchance-88be8.appspot.com",
    messagingSenderId: "849953084393",
    appId: "1:849953084393:web:7e3343f39335211db4b178",
    measurementId: "G-2KJD70D7G3"
});

export default firebase;